import { BrowserRouter, Routes, Route } from "react-router-dom";

import Protected from "./controllers/Protected";

import Login from "./screens/auth/Login";

import Home from "./screens/Home";
import User from "./screens/User";
import Secretary from "./screens/Secretary";
import BusinessDocument from "./screens/BusinessDocument";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>

          <Route path="/" element={Protected(Home)}></Route>
          <Route path="/user/:id" element={Protected(User)}></Route>
          <Route
            path="/user/:id/documents"
            element={Protected(BusinessDocument)}
          ></Route>
          <Route path="/secretary" element={Protected(Secretary)}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
