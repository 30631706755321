import React, { Component } from 'react'
import axios from 'axios'

export class Login extends Component {
  constructor() {
    super()

    this.state = {
      email: '',
      password: ''
    }

    this.login = this.login.bind(this)
  }

  async login(event) {
    event.preventDefault();

    let { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/login`, { email: this.state.email, password: this.state.password }, { headers: { 'Content-Type': 'application/json' } })

    if (data.success) {
      localStorage.setItem('token', data.token)
      window.location.href = '/'
    } else {
      console.log(data)
      alert('Something went wrong')
    }
  }

  render() {
    return (
      <div className='login my-5'>
        <div className="container">
          <form onSubmit={this.login}>
            <div className="mb-3">
              <label className='form-label'>Email Address</label>
              <input type="email" className='form-control' required onChange={(event) => this.setState({ email: event.target.value })}/>
            </div>

            <div className="mb-3">
              <label className='form-label'>Password</label>
              <input type="password" className='form-control' required onChange={(event) => this.setState({ password: event.target.value })}/>
            </div>
            
            <div className="d-grid">
              <button className="btn btn-primary my-3" type='submit'>Submit</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default Login