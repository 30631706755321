import React, { Component } from "react";
import axios from "axios";

import { withRouter } from "../controllers/withRouter";

import "./user.scss";

import Milestone from "./Milestone.json";

export class User extends Component {
  constructor() {
    super();

    this.state = {
      user: null,
      business: null,
      tradeLicense: 0,
      bin: 0,
      tradeLicenseCorrection: 0,
      tradeLicenseRenewal: 0,
      dbid: 0,
      erc: 0,
      irc: 0,
      ircRenewal: 0,
      ercRenewal: 0,
      vatFiling: 0,
      companyFormation: 0,
      tin: 0,
      limitedPartnership: 0,
      copyright: 0,
      trademark: 0,
      taxFiling: 0,
      auditFiling: 0,
      businessType: "",
      name: "",
      phoneNumber: "",
      businessName: "",
      businessAddress: "",
    };

    this.update = this.update.bind(this);
  }

  async componentDidMount() {
    let BusinessData = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/business`,
      { id: this.props.params.id },
      { headers: { authorization: `Bearer ${localStorage.getItem("token")}` } }
    );

    let { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/user-details/${this.props.params.id}`,
      { headers: { authorization: `Bearer ${localStorage.getItem("token")}` } }
    );

    this.setState({
      business: BusinessData.data.business,
      user: data.data,
      businessType: data.data.businessType,
      name: data.data.name,
      phoneNumber: data.data.phoneNumber,
      businessName: BusinessData.data.business.businessName,
      businessAddress: BusinessData.data.business.businessAddress,
    });
    console.log(this.state);
  }

  async update(service) {
    if (service === "tradeLicense") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "tradeLicense",
          step: this.state.tradeLicense,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("Trade License have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "bin") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "bin",
          step: this.state.bin,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("BIN have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "tradeLicenseCorrection") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "tradeLicenseCorrection",
          step: this.state.tradeLicenseCorrection,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("Trade License Correction have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "tradeLicenseRenewal") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "tradeLicenseRenewal",
          step: this.state.tradeLicenseRenewal,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("Trade License Renewal have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "dbid") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "dbid",
          step: this.state.dbid,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("DBID have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "erc") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "erc",
          step: this.state.erc,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("ERC have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "irc") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "irc",
          step: this.state.irc,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("IRC have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "ircRenewal") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "ircRenewal",
          step: this.state.ircRenewal,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("IRC Renewal have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "ercRenewal") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "ercRenewal",
          step: this.state.ercRenewal,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("ERC Renewal have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "vatFiling") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "vatFiling",
          step: this.state.vatFiling,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("Vat Filing have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "companyFormation") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "companyFormation",
          step: this.state.companyFormation,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("Company Formation have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "tin") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "tin",
          step: this.state.tin,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("TIN have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "limitedPartnership") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "limitedPartnership",
          step: this.state.limitedPartnership,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("Registered Partnership have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "copyright") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "copyright",
          step: this.state.copyright,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("Copyright have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "trademark") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "trademark",
          step: this.state.trademark,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("Trademark have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "taxFiling") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "taxfiling",
          step: this.state.taxFiling,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("Tax Filing have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }

    if (service === "auditFiling") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/update-service`,
        {
          id: this.state.business.user_id,
          service: "auditfiling",
          step: this.state.auditFiling,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert("Audit Filing have been updated");
      else alert("Unexpcted error occured");
      window.location.reload();
    }
  }

  async updateBusiness() {
    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/business-type`,
      {
        businessType: this.state.businessType,
        id: this.state.business.user_id,
      },
      { headers: { authorization: `Bearer ${localStorage.getItem("token")}` } }
    );

    if (data.success) alert("Business type updated!");
    else alert("Unexpect error occured");
    window.location.reload();
  }

  async updateUser() {
    let { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/user/update`,
      {
        id: this.props.params.id,
        name: this.state.name,
        phoneNumber: this.state.phoneNumber,
        businessName: this.state.businessName,
        businessAddress: this.state.businessAddress,
      },
      { headers: { authorization: `Bearer ${localStorage.getItem("token")}` } }
    );

    if (data.success) alert("Changes have been updated!");
    else alert("Unexpected error occured");

    window.location.reload();
  }

  render() {
    return (
      <div className="user-profile my-3">
        <div className="container">
          <h1 className="mb-5">User Profile</h1>
          <div className="details">
            <div className="row">
              <div className="col-md-6 pb-3">
                <h5>Name</h5>
                <input
                  value={this.state.name}
                  onChange={(event) =>
                    this.setState({ name: event.target.value })
                  }
                />
              </div>

              <div className="col-md-6 pb-3">
                <h5>Email</h5>
                <p>{this.state.user?.email}</p>
              </div>

              <div className="col-md-6 pb-3">
                <h5>Phone Number</h5>
                <input
                  value={this.state.phoneNumber}
                  onChange={(event) =>
                    this.setState({ phoneNumber: event.target.value })
                  }
                />
              </div>

              <div className="col-md-6 pb-3">
                <h5>Type of business</h5>
                <p>{this.state.user?.businessType}</p>
              </div>

              <div className="col-md-6 pb-3">
                <h5>Business Name</h5>
                <input
                  value={this.state.businessName}
                  onChange={(event) =>
                    this.setState({ businessName: event.target.value })
                  }
                />
              </div>

              <div className="col-md-6 pb-3">
                <h5>Position</h5>
                <p>{this.state.business?.shareholders}</p>
              </div>

              <div className="col-md-6 pb-3">
                <h5>Business Address</h5>
                <input
                  value={this.state.businessAddress}
                  onChange={(event) =>
                    this.setState({ businessAddress: event.target.value })
                  }
                />
              </div>

              <div className="col-md-6 pb-3">
                <h5>City Corporation</h5>
                <p>{this.state.business?.cityCorporation}</p>
              </div>

              <div className="d-grid">
                <button
                  className="btn btn-primary mb-5"
                  onClick={() => this.updateUser()}
                >
                  Submit
                </button>
              </div>
            </div>

            <h4>Business Documents</h4>
            <div className="d-grid">
              <button
                className="btn btn-primary my-3"
                onClick={() =>
                  (window.location.href = `/user/${this.props.params.id}/documents`)
                }
              >
                Upload documents
              </button>
            </div>

            <h4 className="mb-3">
              Update type of business for {this.state.user?.name}
            </h4>
            <select
              className="business-type form-select"
              defaultValue={this.state.businessType}
              onChange={(event) =>
                this.setState({ businessType: event.target.value })
              }
            >
              <option value="Sole proprietorship">Sole proprietorship</option>
              <option value="One Person Company">One Person Company</option>
              <option value="Private LTD">Private LTD</option>
              <option value="Partnership">Partnership</option>
              <option value="Association">Association</option>
              <option value="Foreign Company">Foreign Company</option>
            </select>
            <div className="d-grid">
              <button
                className="btn btn-primary mt-3"
                onClick={() => this.updateBusiness()}
              >
                Submit
              </button>
            </div>
          </div>

          <div className="services">
            <h1>Services</h1>

            {this.state.business?.tradeLicense?.status ? (
              <div className="service tradeLicense">
                <h2 className="mb-3">Trade License</h2>
                {this.state.business?.tradeLicense.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(
                      this.state.business?.tradeLicense.timestamp
                    ).toString()}
                  </p>
                ) : null}
                <h4>Attachmeds</h4>
                <p>
                  NID:{" "}
                  <a
                    href={this.state.business?.tradeLicense.NID}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {this.state.business?.tradeLicense.NID}
                  </a>
                </p>
                <p>
                  Passport Picture:{" "}
                  <a
                    href={this.state.business?.tradeLicense.passportPicture}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {this.state.business?.tradeLicense.passportPicture}
                  </a>
                </p>
                <p>
                  Rental Agreement:{" "}
                  <a
                    href={this.state.business?.tradeLicense.rentalAgreement}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {this.state.business?.tradeLicense.rentalAgreement}
                  </a>
                </p>
                <p>
                  Utility Bill:{" "}
                  <a
                    href={this.state.business?.tradeLicense.utilityBill}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {this.state.business?.tradeLicense.utilityBill}
                  </a>
                </p>
                <p>
                  AOA:{" "}
                  <a
                    href={this.state.business?.tradeLicense.AOA}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {this.state.business?.tradeLicense.AOA}
                  </a>
                </p>
                <p>
                  Incorporation Certificate:{" "}
                  <a
                    href={this.state.business?.tradeLicense.iCertificate}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {this.state.business?.tradeLicense.iCertificate}
                  </a>
                </p>
                <p>
                  Partnership Agreement:{" "}
                  <a
                    href={
                      this.state.business?.tradeLicense.partnershipAgreement
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {this.state.business?.tradeLicense.partnershipAgreement}
                  </a>
                </p>

                <h4>Information</h4>
                <p>
                  Category of Business:{" "}
                  {this.state.business?.tradeLicense.categoryBusiness}
                </p>
                <p>
                  Incorporation Number:{" "}
                  {this.state.business?.tradeLicense.incNumber}
                </p>
                <p>
                  Paid up capital:{" "}
                  {this.state.business?.tradeLicense.paidUpCapital}
                </p>
                <p>
                  Partnership Type:{" "}
                  {this.state.business?.tradeLicense.partnershipType}
                </p>
                <p>
                  Number of partners:{" "}
                  {this.state.business?.tradeLicense.noOfPartners}
                </p>
                <p>
                  Transaction ID:{" "}
                  {this.state.business?.tradeLicense.transactionID}
                </p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {
                    Milestone["tradeLicense"][
                      this.state.business?.tradeLicense.step
                    ]
                  }
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ tradeLicense: event.target.value })
                  }
                >
                  {Milestone["tradeLicense"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("tradeLicense")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.bin?.status ? (
              <div className="service bin">
                <h1 className="mb-3">BIN</h1>
                {this.state.business?.bin.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(this.state.business?.bin.timestamp).toString()}
                  </p>
                ) : null}

                <h4>Attachments</h4>
                <p>
                  NID:{" "}
                  <a
                    href={this.state.business?.bin.nid}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.bin.nid}
                  </a>
                </p>
                <p>
                  Trade License:{" "}
                  <a
                    href={this.state.business?.bin.tradeLicense}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.bin.tradeLicense}
                  </a>
                </p>
                <p>
                  TIN:{" "}
                  <a
                    href={this.state.business?.bin.tin}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.bin.tin}
                  </a>
                </p>
                <p>
                  Bank Solvency Certificate:{" "}
                  <a
                    href={this.state.business?.bin.bsCertificate}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.bin.bsCertificate}
                  </a>
                </p>
                <p>
                  Incorporation Certificate:{" "}
                  <a
                    href={this.state.business?.bin.incCertificate}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.bin.incCertificate}
                  </a>
                </p>
                <p>
                  Parntership Agreement:{" "}
                  <a
                    href={this.state.business?.bin.partnershipAgreement}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.bin.partnershipAgreement}
                  </a>
                </p>
                <p>
                  Form XII:{" "}
                  <a
                    href={this.state.business?.bin.formxii}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.bin.formxii}
                  </a>
                </p>

                <h4>Information</h4>
                <p>No of Employees: {this.state.business?.bin.noEmployees}</p>
                <p>
                  Project Turnover: {this.state.business?.bin.projectTurnover}
                </p>
                <p>
                  Category of business:{" "}
                  {this.state.business?.bin.categoryBusiness}
                </p>
                <p>
                  Partnership type: {this.state.business?.bin.partnershipType}
                </p>
                <p>
                  Number of partners: {this.state.business?.bin.noOfPartners}
                </p>
                <p>Transaction ID: {this.state.business?.bin.transactionID}</p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {Milestone["bin"][this.state.business?.bin.step]}
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ bin: event.target.value })
                  }
                >
                  {Milestone["bin"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("bin")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.tradeLicenseCorrection?.status ? (
              <div className="service tradeLicenseCorrection">
                <h1 className="mb-3">Trade License Correction</h1>
                {this.state.business?.tradeLicenseCorrection.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(
                      this.state.business?.tradeLicenseCorrection.timestamp
                    ).toString()}
                  </p>
                ) : null}

                <h4>Attachments</h4>
                <p>
                  Current Trade License:{" "}
                  <a
                    href={
                      this.state.business?.tradeLicenseCorrection
                        .currentTradeLicense
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    {
                      this.state.business?.tradeLicenseCorrection
                        .currentTradeLicense
                    }
                  </a>
                </p>

                <h4>Information</h4>
                <p>
                  Category of business:{" "}
                  {this.state.business?.tradeLicenseCorrection.categoryBusiness}
                </p>
                <p>
                  Correction Type:{" "}
                  {this.state.business?.tradeLicenseCorrection.correctionType}
                </p>
                <p>
                  Transaction ID:{" "}
                  {this.state.business?.tradeLicenseCorrection.transactionID}
                </p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {
                    Milestone["tradeLicense"][
                      this.state.business?.tradeLicenseCorrection.step
                    ]
                  }
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({
                      tradeLicenseCorrection: event.target.value,
                    })
                  }
                >
                  {Milestone["tradeLicense"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("tradeLicenseCorrection")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.tradeLicenseRenewal?.status ? (
              <div className="service tradeLicenseRenewal">
                <h1 className="mb-3">Trade License Renewal</h1>
                {this.state.business?.tradeLicenseRenewal.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(
                      this.state.business?.tradeLicenseRenewal.timestamp
                    ).toString()}
                  </p>
                ) : null}

                <h4>Attachments</h4>
                <p>
                  Current Trade License:{" "}
                  <a
                    href={
                      this.state.business?.tradeLicenseRenewal
                        .currentTradeLicense
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    {
                      this.state.business?.tradeLicenseRenewal
                        .currentTradeLicense
                    }
                  </a>
                </p>
                <p>
                  NID:{" "}
                  <a
                    href={this.state.business?.tradeLicenseRenewal.nid}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.tradeLicenseRenewal.nid}
                  </a>
                </p>
                <p>
                  Passport Picture:{" "}
                  <a
                    href={
                      this.state.business?.tradeLicenseRenewal.passportPicture
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.tradeLicenseRenewal.passportPicture}
                  </a>
                </p>

                <h4>Information</h4>
                <p>
                  Category of business:{" "}
                  {this.state.business?.tradeLicenseRenewal.categoryBusiness}
                </p>
                <p>
                  City Corporation:{" "}
                  {this.state.business?.tradeLicenseRenewal.cityCorporation}
                </p>
                <p>
                  Transaction ID:{" "}
                  {this.state.business?.tradeLicenseRenewal.transactionID}
                </p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {
                    Milestone["tradeLicense"][
                      this.state.business?.tradeLicenseRenewal.step
                    ]
                  }
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ tradeLicenseRenewal: event.target.value })
                  }
                >
                  {Milestone["tradeLicense"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("tradeLicenseRenewal")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.dbid?.status ? (
              <div className="service dbid">
                <h1 className="mb-3">DBID</h1>
                {this.state.business?.dbid.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(this.state.business?.dbid.timestamp).toString()}
                  </p>
                ) : null}

                <h4>Attachments</h4>
                <p>
                  Passport Picture:{" "}
                  <a
                    href={this.state.business?.dbid.passportPicture}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.dbid.passportPicture}
                  </a>
                </p>
                <p>
                  NID:{" "}
                  <a
                    href={this.state.business?.dbid.nid}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.dbid.nid}
                  </a>
                </p>
                <p>
                  Trade License:{" "}
                  <a
                    href={this.state.business?.dbid.tradeLicense}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.dbid.tradeLicense}
                  </a>
                </p>
                <p>
                  Rental Agreement:{" "}
                  <a
                    href={this.state.business?.dbid.rentalAgreement}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.dbid.rentalAgreement}
                  </a>
                </p>
                <p>
                  BIN:{" "}
                  <a
                    href={this.state.business?.dbid.bin}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.dbid.bin}
                  </a>
                </p>
                <p>
                  TIN:{" "}
                  <a
                    href={this.state.business?.dbid.tin}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.dbid.tin}
                  </a>
                </p>
                <p>
                  NID of house owner:{" "}
                  <a
                    href={this.state.business?.dbid.nidHouseOwner}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.dbid.nidHouseOwner}
                  </a>
                </p>
                <p>
                  Incorporation Certificate:{" "}
                  <a
                    href={this.state.business?.dbid.incCertificate}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.dbid.incCertificate}
                  </a>
                </p>
                <p>
                  AOA:{" "}
                  <a
                    href={this.state.business?.dbid.AOA}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.dbid.AOA}
                  </a>
                </p>
                <p>
                  Form XII:{" "}
                  <a
                    href={this.state.business?.dbid.formxii}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.dbid.formxii}
                  </a>
                </p>

                <h4>Information</h4>
                <p>Facebook Page: {this.state.business?.dbid.facebookPage}</p>
                <p>Website: {this.state.business?.dbid.website}</p>
                <p>
                  Partnership Type: {this.state.business?.dbid.partnershipType}
                </p>
                <p>
                  Number of partners: {this.state.business?.dbid.noOfPartners}
                </p>
                <p>Transaction ID: {this.state.business?.dbid.transactionID}</p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {Milestone["dbid"][this.state.business?.dbid.step]}
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ dbid: event.target.value })
                  }
                >
                  {Milestone["dbid"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("dbid")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.erc?.status ? (
              <div className="service erc">
                <h1 className="mb-3">ERC</h1>
                {this.state.business?.erc.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(this.state.business?.erc.timestamp).toString()}
                  </p>
                ) : null}

                <h4>Attachments</h4>
                <p>
                  Trade License:{" "}
                  <a
                    href={this.state.business?.erc.tradeLicense}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.erc.tradeLicense}
                  </a>
                </p>
                <p>
                  Valid membership certificate of chamber / trade association
                  representing affiliated business:{" "}
                  <a
                    href={this.state.business?.erc.msCertificate}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.erc.msCertificate}
                  </a>
                </p>
                <p>
                  Bank transparency certificate / Verified copy of the
                  certificate:{" "}
                  <a
                    href={this.state.business?.erc.btCertificate}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.erc.btCertificate}
                  </a>
                </p>
                <p>
                  TIN:{" "}
                  <a
                    href={this.state.business?.erc.tin}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.erc.tin}
                  </a>
                </p>
                <p>
                  Passport Picture:{" "}
                  <a
                    href={this.state.business?.erc.passportPicture}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.erc.passportPicture}
                  </a>
                </p>
                <p>
                  NID:{" "}
                  <a
                    href={this.state.business?.erc.nid}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.erc.nid}
                  </a>
                </p>
                <p>
                  Partnership Document:{" "}
                  <a
                    href={this.state.business?.erc.partnershipDocument}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.erc.partnershipDocument}
                  </a>
                </p>
                <p>
                  AOA:{" "}
                  <a
                    href={this.state.business?.erc.AOA}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.erc.AOA}
                  </a>
                </p>
                <p>
                  Treasury Shipment:{" "}
                  <a
                    href={this.state.business?.erc.treasuryShipment}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.erc.treasuryShipment}
                  </a>
                </p>

                <h4>Information</h4>
                <p>
                  Partnership Type: {this.state.business?.erc.partnershipType}
                </p>
                <p>
                  Number of partners: {this.state.business?.erc.noOfPartners}
                </p>
                <p>Transaction ID: {this.state.business?.erc.transactionID}</p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {Milestone["erc"][this.state.business?.erc.step]}
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ erc: event.target.value })
                  }
                >
                  {Milestone["erc"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("erc")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.limitedPartnership?.status ? (
              <div className="service limitedPartnership">
                <h1 className="mb-3">Registered Partnership</h1>
                {this.state.business?.limitedPartnership.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(
                      this.state.business?.limitedPartnership.timestamp
                    ).toString()}
                  </p>
                ) : null}

                <h4>Attachments</h4>
                <p>
                  Partnership Agreement:{" "}
                  <a
                    href={
                      this.state.business?.limitedPartnership
                        .partnershipAgreement
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    {
                      this.state.business?.limitedPartnership
                        .partnershipAgreement
                    }
                  </a>
                </p>

                <h4>Information</h4>
                <p>
                  Partnership Type:{" "}
                  {this.state.business?.limitedPartnership.typeOfPartnership}
                </p>
                <p>
                  Number of partners:{" "}
                  {this.state.business?.limitedPartnership.noOfPartners}
                </p>
                <p>
                  Nature of partnership:{" "}
                  {this.state.business?.limitedPartnership.naturePartnership}
                </p>
                <p>
                  Partnership Type:{" "}
                  {this.state.business?.limitedPartnership.companyName}
                </p>
                <p>
                  Number of partners:{" "}
                  {this.state.business?.limitedPartnership.companyAddress}
                </p>
                <p>
                  Transaction ID:{" "}
                  {this.state.business?.limitedPartnership.transactionID}
                </p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {
                    Milestone[
                      this.state.business?.limitedPartnership.typeOfPartnership
                    ][this.state.business?.limitedPartnership.step]
                  }
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ limitedPartnership: event.target.value })
                  }
                >
                  {Milestone[
                    this.state.business?.limitedPartnership.typeOfPartnership
                  ].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("limitedPartnership")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.irc?.status ? (
              <div className="service irc">
                <h1 className="mb-3">IRC</h1>
                {this.state.business?.irc.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(this.state.business?.irc.timestamp).toString()}
                  </p>
                ) : null}

                <h4>Attachments</h4>
                <p>
                  Trade License:{" "}
                  <a
                    href={this.state.business?.irc.tradeLicense}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.irc.tradeLicense}
                  </a>
                </p>
                <p>
                  Valid membership certificate of chamber / trade association
                  representing affiliated business:{" "}
                  <a
                    href={this.state.business?.irc.msCertificate}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.irc.msCertificate}
                  </a>
                </p>
                <p>
                  Bank transparency certificate / Verified copy of the
                  certificate:{" "}
                  <a
                    href={this.state.business?.irc.btCertificate}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.irc.btCertificate}
                  </a>
                </p>
                <p>
                  TIN:{" "}
                  <a
                    href={this.state.business?.irc.tin}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.irc.tin}
                  </a>
                </p>
                <p>
                  Passport Picture:{" "}
                  <a
                    href={this.state.business?.irc.passportPicture}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.irc.passportPicture}
                  </a>
                </p>
                <p>
                  NID:{" "}
                  <a
                    href={this.state.business?.irc.nid}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.irc.nid}
                  </a>
                </p>
                <p>
                  Partnership Document:{" "}
                  <a
                    href={this.state.business?.irc.partnershipDocument}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.irc.partnershipDocument}
                  </a>
                </p>
                <p>
                  AOA:{" "}
                  <a
                    href={this.state.business?.irc.AOA}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.irc.AOA}
                  </a>
                </p>
                <p>
                  Treasury Shipment:{" "}
                  <a
                    href={this.state.business?.irc.treasuryShipment}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.irc.treasuryShipment}
                  </a>
                </p>

                <h4>Information</h4>
                <p>Import Limit: {this.state.business?.irc.importLimit}</p>
                <p>
                  Partnership Type: {this.state.business?.irc.partnershipType}
                </p>
                <p>
                  Number of partners: {this.state.business?.irc.noOfPartners}
                </p>
                <p>Transaction ID: {this.state.business?.irc.transactionID}</p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {Milestone["irc"][this.state.business?.irc.step]}
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ irc: event.target.value })
                  }
                >
                  {Milestone["irc"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("irc")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.ircRenewal?.status ? (
              <div className="service irc">
                <h1 className="mb-3">IRC Renewal</h1>
                {this.state.business?.ircRenewal.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(
                      this.state.business?.ircRenewal.timestamp
                    ).toString()}
                  </p>
                ) : null}

                <h4>Attachments</h4>
                <p>
                  IRC Certificate:{" "}
                  <a
                    href={this.state.business?.ircRenewal.ircCertificate}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.ircRenewal.bin}
                  </a>
                </p>

                <h4>Information</h4>
                <p>
                  Company name: {this.state.business?.ircRenewal.companyName}
                </p>
                <p>
                  Import Limit: {this.state.business?.ircRenewal.importLimit}
                </p>
                <p>
                  IRC Number:{" "}
                  {this.state.business?.ircRenewal.ircCertificateNumber}
                </p>
                <p>
                  Transaction ID:{" "}
                  {this.state.business?.ircRenewal.transactionID}
                </p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {
                    Milestone["ircRenewal"][
                      this.state.business?.ircRenewal.step
                    ]
                  }
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ ircRenewal: event.target.value })
                  }
                >
                  {Milestone["ircRenewal"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("ircRenewal")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.ercRenewal?.status ? (
              <div className="service erc">
                <h1 className="mb-3">ERC Renewal</h1>
                {this.state.business?.ercRenewal.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(
                      this.state.business?.ercRenewal.timestamp
                    ).toString()}
                  </p>
                ) : null}

                <h4>Attachments</h4>
                <p>
                  ERC Certificate:{" "}
                  <a
                    href={this.state.business?.ercRenewal.ercCertificate}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.ercRenewal.ercCertificate}
                  </a>
                </p>

                <h4>Information</h4>
                <p>
                  Company name: {this.state.business?.ercRenewal.companyName}
                </p>
                <p>
                  ERC Number:{" "}
                  {this.state.business?.ercRenewal.ercCertificateNumber}
                </p>
                <p>
                  Transaction ID:{" "}
                  {this.state.business?.ercRenewal.transactionID}
                </p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {
                    Milestone["ercRenewal"][
                      this.state.business?.ercRenewal.step
                    ]
                  }
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ ercRenewal: event.target.value })
                  }
                >
                  {Milestone["ercRenewal"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("ercRenewal")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.vatFiling?.status ? (
              <div className="service vat-filing">
                <h1 className="mb-3">Vat Filing</h1>
                {this.state.business?.vatFiling.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(
                      this.state.business?.vatFiling.timestamp
                    ).toString()}
                  </p>
                ) : null}

                <h4>Information</h4>
                <p>
                  Company Name: {this.state.business?.vatFiling.companyName}
                </p>
                <p>
                  Company Type: {this.state.business?.vatFiling.companyType}
                </p>
                <p>
                  Probable Income:{" "}
                  {this.state.business?.vatFiling.probableIncome}
                </p>
                <p>
                  Transaction ID: {this.state.business?.vatFiling.transactionID}
                </p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {
                    Milestone["perMonthVatFiling"][
                      this.state.business?.vatFiling.step
                    ]
                  }
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ vatFiling: event.target.value })
                  }
                >
                  {Milestone["perMonthVatFiling"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("vatFiling")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.companyFormation?.status ? (
              <div className="service vat-filing">
                <h1 className="mb-3">Company Formation</h1>
                {this.state.business?.companyFormation.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(
                      this.state.business?.companyFormation.timestamp
                    ).toString()}
                  </p>
                ) : null}

                <h4>Attachments</h4>
                <p>
                  Form XII:{" "}
                  <a
                    href={this.state.business?.companyFormation.formxii}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.companyFormation.formxii}
                  </a>
                </p>

                <h4>Information</h4>
                <p>
                  Company Name:{" "}
                  {this.state.business?.companyFormation.companyName}
                </p>
                <p>
                  Authorized Capital:{" "}
                  {this.state.business?.companyFormation.authorizedCapital}
                </p>
                <p>
                  Business Address:{" "}
                  {this.state.business?.companyFormation.businessAddress}
                </p>
                <p>
                  Business Activity:{" "}
                  {this.state.business?.companyFormation.businessActivity}
                </p>
                <p>
                  Number of shareholders:{" "}
                  {this.state.business?.companyFormation.noOfShareholders}
                </p>
                <p>
                  Transaction ID:{" "}
                  {this.state.business?.companyFormation.transactionID}
                </p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {
                    Milestone["companyFormation"][
                      this.state.business?.companyFormation.step
                    ]
                  }
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ companyFormation: event.target.value })
                  }
                >
                  {Milestone["companyFormation"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("companyFormation")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.tin?.status ? (
              <div className="service vat-filing">
                <h1 className="mb-3">TIN</h1>
                {this.state.business?.tin.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(this.state.business?.tin.timestamp).toString()}
                  </p>
                ) : null}

                <h4>Attachments</h4>
                <p>
                  NID:{" "}
                  <a
                    href={this.state.business?.tin.nid}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.tin.nid}
                  </a>
                </p>

                <h4>Information</h4>
                <p>Tin Type: {this.state.business?.tin.tinType}</p>
                <p>Source of Income: {this.state.business?.tin.sourceIncome}</p>
                <p>Purpose of TIN: {this.state.business?.tin.purposeTin}</p>
                <p>Company Name: {this.state.business?.tin.companyName}</p>
                <p>
                  Company Address: {this.state.business?.tin.companyAddress}
                </p>
                <p>Transaction ID: {this.state.business?.tin.transactionID}</p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {Milestone["tin"][this.state.business?.tin.step]}
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ tin: event.target.value })
                  }
                >
                  {Milestone["tin"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("tin")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.copyright?.status ? (
              <div className="service copyright">
                <h1 className="mb-3">Copyright</h1>
                {this.state.business?.copyright.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(
                      this.state.business?.copyright.timestamp
                    ).toString()}
                  </p>
                ) : null}
                <h4>Attachments</h4>
                <p>
                  Passport Picture:{" "}
                  <a
                    href={this.state.business?.copyright.passportPicture}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.copyright.passportPicture}
                  </a>
                </p>
                <p>
                  NID:{" "}
                  <a
                    href={this.state.business?.copyright.nid}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.copyright.nid}
                  </a>
                </p>
                <p>
                  AOA:{" "}
                  <a
                    href={this.state.business?.copyright.aoa}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.copyright.aoa}
                  </a>
                </p>
                <p>
                  Incorporation Certificate:{" "}
                  <a
                    href={
                      this.state.business?.copyright.incorporationCertificate
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.copyright.incorporationCertificate}
                  </a>
                </p>
                <p>
                  Partnership Agreement:{" "}
                  <a
                    href={this.state.business?.copyright.partnershipAgreement}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.copyright.partnershipAgreement}
                  </a>
                </p>
                <p>
                  Trade License:{" "}
                  <a
                    href={this.state.business?.copyright.tradeLicense}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.copyright.tradeLicense}
                  </a>
                </p>
                <p>
                  TIN Certificate:{" "}
                  <a
                    href={this.state.business?.copyright.tinCertificate}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.copyright.tinCertificate}
                  </a>
                </p>

                <h4>Information</h4>
                <p>
                  Type of Copyright:{" "}
                  {this.state.business?.copyright.typeOfCopyright}
                </p>
                <p>
                  Transaction ID: {this.state.business?.copyright.transactionID}
                </p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {Milestone["copyright"][this.state.business?.copyright.step]}
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ copyright: event.target.value })
                  }
                >
                  {Milestone["copyright"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("copyright")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.trademark?.status ? (
              <div className="service trademark">
                <h1 className="mb-3">Trademark</h1>
                {this.state.business?.trademark.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(
                      this.state.business?.trademark.timestamp
                    ).toString()}
                  </p>
                ) : null}
                <h4>Attachments</h4>

                <p>
                  Particulars of the mark/logo/device prints or representations:{" "}
                  <a
                    href={this.state.business?.trademark.particulars}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.trademark.particulars}
                  </a>
                </p>

                <h4>Information</h4>
                <p>
                  Type of business:{" "}
                  {this.state.business?.trademark.typeOfBusiness}
                </p>
                <p>
                  Legal Status of business:{" "}
                  {this.state.business?.trademark.legalStatusOfBusiness}
                </p>
                <p>
                  Website/Facebook link:{" "}
                  {this.state.business?.trademark.websiteLink}
                </p>
                <p>
                  Transaction ID: {this.state.business?.trademark.transactionID}
                </p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {Milestone["trademark"][this.state.business?.trademark.step]}
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ trademark: event.target.value })
                  }
                >
                  {Milestone["trademark"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("trademark")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.taxFiling?.status ? (
              <div className="service taxFiling">
                <h1 className="mb-3">Tax Filing</h1>
                {this.state.business?.taxFiling.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(
                      this.state.business?.taxFiling.timestamp
                    ).toString()}
                  </p>
                ) : null}
                <h4>Attachments</h4>

                <p>
                  Tin Certificate:{" "}
                  <a
                    href={this.state.business?.taxFiling.tinCertificate}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.taxFiling.tinCertificate}
                  </a>
                </p>

                <h4>Information</h4>
                <p>
                  Type of Tax Filing:{" "}
                  {this.state.business?.taxFiling.typeOfTaxFiling}
                </p>
                <p>
                  Source of income:{" "}
                  {this.state.business?.taxFiling.sourceOfIncome}
                </p>
                <p>
                  Approximate Total Income:{" "}
                  {this.state.business?.taxFiling.totalIncome}
                </p>
                <p>
                  Transaction ID: {this.state.business?.taxFiling.transactionID}
                </p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {Milestone["taxfiling"][this.state.business?.taxFiling.step]}
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ taxFiling: event.target.value })
                  }
                >
                  {Milestone["taxfiling"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("taxFiling")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.auditFiling?.status ? (
              <div className="service auditFiling">
                <h1 className="mb-3">Audit Filing</h1>
                {this.state.business?.auditFiling.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(
                      this.state.business?.auditFiling.timestamp
                    ).toString()}
                  </p>
                ) : null}
                <h4>Attachments</h4>

                <p>
                  Incorporation Certificate:{" "}
                  <a
                    href={
                      this.state.business?.auditFiling.incorporationCertificate
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.auditFiling.incorporationCertificate}
                  </a>
                </p>

                <p>
                  Partnership Agreement:{" "}
                  <a
                    href={this.state.business?.auditFiling.partnershipAgreement}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {this.state.business?.auditFiling.partnershipAgreement}
                  </a>
                </p>

                <h4>Information</h4>
                <p>
                  No. of Partners/Shareholders:{" "}
                  {this.state.business?.auditFiling.noOfPartners}
                </p>
                <p>
                  Is audit complete?:{" "}
                  {this.state.business?.auditFiling.auditStatus}
                </p>
                <p>
                  Authorized Capital:{" "}
                  {this.state.business?.auditFiling.authorizedCapital}
                </p>
                <p>
                  Paid-Up Capital:{" "}
                  {this.state.business?.auditFiling.paidUpCapital}
                </p>
                <p>
                  Transaction ID:{" "}
                  {this.state.business?.auditFiling.transactionID}
                </p>

                <h4>Step</h4>
                <p>
                  Current Milestone:{" "}
                  {
                    Milestone["auditfiling"][
                      this.state.business?.auditFiling.step
                    ]
                  }
                </p>
                <select
                  className="form-select"
                  onChange={(event) =>
                    this.setState({ auditFiling: event.target.value })
                  }
                >
                  {Milestone["auditfiling"].map((step, i) => (
                    <option value={i} key={i}>
                      {step}
                    </option>
                  ))}
                </select>
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.update("auditFiling")}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}

            {this.state.business?.paymentGateway ? (
              <div className="service paymentgateway">
                <h1 className="mb-3">Payment Gateway</h1>
                {this.state.business?.paymentGateway.timestamp ? (
                  <p>
                    Service started at:{" "}
                    {new Date(
                      this.state.business?.paymentGateway.timestamp
                    ).toString()}
                  </p>
                ) : null}

                <h4>Information</h4>
                <p>
                  Business Name:{" "}
                  {this.state.business?.paymentGateway.businessName}
                </p>
                <p>
                  Type of business:{" "}
                  {this.state.business?.paymentGateway.typeOfBusiness}
                </p>
                <p>
                  Website/Facebook page:{" "}
                  {this.state.business?.paymentGateway.websitePage}
                </p>
                <p>
                  Legal Entity type:{" "}
                  {this.state.business?.paymentGateway.legalEntity}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(User);
