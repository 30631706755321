import React, { Component } from "react";
import axios from "axios";

export class Home extends Component {
  constructor() {
    super();

    this.state = {
      data: null,
    };
  }

  async componentDidMount() {
    let { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/user`,
      { headers: { authorization: `Bearer ${localStorage.getItem("token")}` } }
    );

    if (data.success) {
      this.setState({ data: data.data });
    } else {
      alert("Something went wrong. Please login again");
      window.location.href = "/login";
    }
  }

  logout() {
    localStorage.removeItem("token");
    window.location.href = "/login";
  }

  async deleteUser(id) {
    let prompt = window.prompt(`Please type CONFIRM to delete user: ${id}`);

    console.log(prompt);

    if (prompt.toLowerCase() === "confirm") {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/user/delete`,
        { id },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) alert(`User id: ${id} has been successfully deleted`);
      window.location.href = "/";
    } else return;
  }

  render() {
    return (
      <div className="home">
        <div className="container">
          <h1>All users</h1>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Business Type</th>
                <th scope="col">Profile</th>
              </tr>
            </thead>

            <tbody>
              {this.state.data?.map((data, i) => (
                <tr key={i}>
                  <th scope="row">{data._id}</th>
                  <th>{data.name}</th>
                  <th>{data.email}</th>
                  <th>{data.businessType}</th>
                  <th>
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        (window.location.href = `/user/${data._id}`)
                      }
                    >
                      View
                    </button>
                    <button
                      className="ms-2 btn btn-danger"
                      onClick={() => this.deleteUser(data._id)}
                    >
                      Delete
                    </button>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>

          <button
            className="btn btn-primary mt-4"
            onClick={() => this.logout()}
          >
            Logout
          </button>
          <button
            className="btn btn-primary mt-4 mx-2"
            onClick={() => (window.location.href = "/secretary")}
          >
            View Secretary Form
          </button>
        </div>
      </div>
    );
  }
}

export default Home;
