import React, { Component } from "react";
import axios from "axios";

import "./user.scss";

export class Secretary extends Component {
  constructor() {
    super();

    this.state = {
      data: null,
    };
  }

  async componentDidMount() {
    let { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/secretary`,
      { headers: { authorization: `Bearer ${localStorage.getItem("token")}` } }
    );

    if (data.success) {
      this.setState({ data: data.data });
      console.log(this.state.data);
    } else {
      console.error(data);
    }
  }

  render() {
    return (
      <div className="user-profile my-3">
        <div className="container">
          <h1 className="mb-5">Secretary Form</h1>
          {this.state.data?.map((d, i) => (
            <div className="service">
              <div className="row">
                <div className="col-md-6 pb-2">
                  <h5>Package</h5>
                  <p>{d.package}</p>
                </div>
                <div className="col-md-6 pb-2">
                  <h5>Company Name</h5>
                  <p>{d.companyName}</p>
                </div>
                <div className="col-md-6 pb-2">
                  <h5>Type of business</h5>
                  <p>{d.typeOfBusiness}</p>
                </div>
                <div className="col-md-6 pb-2">
                  <h5>Legal Status of Business</h5>
                  <p>{d.legalStatusOfBusiness}</p>
                </div>
                <div className="col-md-6 pb-2">
                  <h5>No. of Partners/Shareholders</h5>
                  <p>{d.noOfPartners}</p>
                </div>
                <div className="col-md-6 pb-2">
                  <h5>No. of employee</h5>
                  <p>{d.noOfEmployee}</p>
                </div>
                <div className="col-md-6 pb-2">
                  <h5>Phone Number</h5>
                  <p>{d.phoneNumber}</p>
                </div>
                <div className="col-md-6 pb-2">
                  <h5>Email Address</h5>
                  <p>{d.email}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Secretary;
