import React, { Component } from "react";
import axios from "axios";

import { withRouter } from "../controllers/withRouter";

import "./user.scss";

let services = [
  { name: "Trade License", value: "tradeLicense" },
  { name: "Business Identification Number", value: "bin" },
  { name: "Company Formation", value: "companyFormation" },
  { name: "Registered Partnership", value: "limitedPartnership" },
  { name: "Trade License Correction", value: "tradeLicenseCorrection" },
  { name: "Trade License Renewal", value: "tradeLicenseRenewal" },
  { name: "Tax Identification Number", value: "tin" },
  { name: "Digital Business Indentification", value: "dbid" },
  { name: "Export Registration Certificate", value: "erc" },
  { name: "Import Registration Certificate", value: "irc" },
  { name: "Export Registration Certificate Renewal", value: "ercRenewal" },
  { name: "Import Registration Certificate Renewal", value: "ircRenewal" },
  { name: "Copyright", value: "copyright" },
  { name: "Trademark", value: "trademark" },
  { name: "Monthly Vat Filing", value: "vatFiling" },
  { name: "Audit Filing", value: "auditFiling" },
  { name: "Tax Filing", value: "taxFiling" },
];

export class BusinessDocument extends Component {
  constructor() {
    super();

    this.state = {
      user: null,
      loading: false,
      documentType: services[0].name,
      document: "",
      uploadedDocuments: [],
    };

    this.uploadDocuments = this.uploadDocuments.bind(this);
    this.downloadDocument = this.downloadDocument.bind(this);
  }

  async componentDidMount() {
    let { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/documents/${this.props.params.id}`,
      { headers: { authorization: `Bearer ${localStorage.getItem("token")}` } }
    );

    this.setState({
      user: data.data.user,
      uploadedDocuments: data.data.documents,
    });
  }

  async uploadDocuments(event) {
    event.preventDefault();

    this.setState({ loading: true });

    const formData = new FormData();

    formData.append("bd", this.state.document);
    formData.append("documentType", this.state.documentType);
    formData.append("id", this.state.user.id);

    try {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/upload/document`,
        formData,
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (data.success) {
        this.setState({ loading: false });
        alert(`Document have been uploaded succesfully!`);
        window.location.href = `/user/${this.state.user.id}/documents`;
      } else {
        this.setState({ loading: false });
        alert("Something went wrong! Please try again");
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      alert("Something went wrong! Please try again");
    }
  }

  async downloadDocument(doc) {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/download/document/${doc}`,
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([data]));
      const downloadLink = document.createElement("a");

      downloadLink.href = url;
      downloadLink.setAttribute("download", doc);

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div className="user-profile my-3">
        <div className="container">
          <h1 className="mb-5">Upload document for {this.state.user?.name}</h1>

          {this.state.loading ? (
            <h4 className="my-5">Uploading...</h4>
          ) : (
            <form onSubmit={this.uploadDocuments}>
              <label className="mb-3">Select document type*</label>
              <select
                className="business-type form-select mb-3"
                defaultValue={this.state.documentType}
                onChange={(event) =>
                  this.setState({ documentType: event.target.value })
                }
              >
                {services.map((service, index) => (
                  <option value={service.name} key={index}>
                    {service.name}
                  </option>
                ))}
              </select>

              <label>Upload file*</label>
              <input
                type="file"
                className="form-input"
                onChange={(event) =>
                  this.setState({ document: event.target.files[0] })
                }
                required
              />

              <div className="d-grid">
                <button className="btn btn-primary mt-3" type="submit">
                  Submit
                </button>
              </div>
            </form>
          )}

          <h1 className="my-5">
            Uploaded documents for {this.state.user?.name}
          </h1>
          {this.state.uploadedDocuments?.map((document, index) => (
            <div
              className="d-flex gap-4 align-items-center justify-content-between border border-black p-3 rounded"
              key={index}
            >
              <div>
                <h3>{document.name}</h3>
                <p>
                  Uploaded at: {new Date(document.timestamp).toLocaleString()}
                </p>
              </div>
              <button
                className="btn btn-secondary"
                onClick={() => this.downloadDocument(document.document)}
              >
                Download
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(BusinessDocument);
